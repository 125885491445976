/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://w5qouduhdzcx7cjkcuomba242u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dzco6ids5bdsxbxx2qhre7ry7e",
    "aws_cognito_identity_pool_id": "us-east-1:b7cdf7f3-85eb-4bcf-8957-44a9732079d1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PBa9fpj5h",
    "aws_user_pools_web_client_id": "3io2mh6orv9u5sqbg41613klpb",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "scsumarketreactamplibc2c83f5134140a9af848b913bd429a2-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
